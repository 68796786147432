<template>
  <div class="profile">
    <div class="introduction w">
      <div class="title">
        <span>COMPANY PROFILE</span>
        <span>公司简介</span>
        <span></span>
      </div>
      <div class="text-box">
        <div class="text-box-left">
          <h4>成为国内领先的信息技术与智慧应用服务提供商</h4>
          <span></span>
          <div class="p-text">
            广州市昱安信息技术有限公司，简称“昱安技术”，坐落在广州高新技术产业开发区，具有得天独厚的区位优势和产业氛围。公司秉承“理念创新、技术一流、品质第一”的经营理念，坚持以技术服务客户、用创新维系企业生命、以品质树立企业形象的战略思想。
            <p class="p1">公司拥有自主研发城市管理智能传感器、传感器应用监测管理平台等，包括城市市政设施管理类、环境监测类、建筑监测类传感器。致力于智慧城市信息服务的技术创新，推动智慧城市的发展。</p>
            <p>
              公司通过多年技术投入和研发积累，继承高科技领域数据制作、应用、展示、系统研发和软件研发的经验，提供智慧博物馆、数字博物馆、文物数字化保护、文物预防性保护、文创产品开发设计、数字展馆设计与开发等一体化解决方案、是文博行业全业务、一站式服务供应商。
            </p>
            <p class="p1">公司主营业务包括智慧文旅、智慧城市、数字展陈等。</p>
            <p>“昱安技术”，是国家高新技术企业、广州市科技创新小巨人企业，通过软件企业及软件产品“双软认证”，已获得软件著作权60余项、专利3项。与清华大学、广州大学、深圳大学、厦门大学、南方日报、中科院广州电子技术研究所等科研院校建立了长期产学研合作关系。</p>
          </div>
        </div>
        <div class="text-box-right">
          <img src="../assets/imgs/companyPictures.png" alt="" />
        </div>
      </div>
    </div>
    <div class="culture w">
      <div class="culture-title">
        <div class="culture-title-text">
          <i>CORPORATE CULTURE</i>
          <span>企业文化</span>
          <em></em>
        </div>
      </div>
      <div class="vision">
        <span>公司愿景</span>
        <p>成为国内领先的信息技术与智慧应用服务提供商</p>
        <div class="imgs">
          <img src="../assets/imgs/vision01.jpg" alt="" />
        </div>
      </div>
      <div class="core">
        <span>核心价值</span>
        <p>以客户为中心，以品质为生命，客户满意为标准</p>
        <div class="imgs">
          <img src="../assets/imgs/vision02.jpg" alt=""/>
        </div>
      </div>
      <div class="target">
        <span>经营理念</span>
        <p>理念创新、技术一流、品质第一</p>
        <div class="imgs">
          <img src="../assets/imgs/vision03.jpg" alt="" />
        </div>
      </div>
      <div class="arts">
        <span>企业精神</span>
        <p>制度、执行、创新、专注</p>
        <div class="imgs">
          <img src="../assets/imgs/vision05.png" alt="" />
        </div>
      </div>
      <!-- <div class="spirit">
        <span>企业精神</span>
        <p>制度、执行、创新、专注</p>
        <div class="imgs">
          <img src="../assets/imgs/vision05.png" alt="" />
        </div>
      </div> -->
    </div>
    <!-- 产品中心 -->
    <div class="product-center">
      <div class="product-center-banner">
        <div class="product-info">
          <i>QUALIFICATION&nbsp;&nbsp;&nbsp;&nbsp;HONOR</i>
          <span>资质荣誉</span>
          <i class="line"></i>
        </div>
        <!-- 轮播图 -->
        <div class="product-carousel">
          <div class="prev">
            <em class="el-icon-arrow-left" @click="arrowClick('prev')"></em>
          </div>
          <el-carousel :interval="5000" arrow="never" ref="cardShow">
            <el-carousel-item>
              <div class="exhibit-img box">
                <img src="../assets/imgs/pictureFrame01.png" alt="" />
                <div class="title">质量管理体系认证证书</div>
              </div>
              <div class="exhibit-img box">
                <img src="../assets/imgs/pictureFrame02.png" alt="" />
                <div class="title">环境管理体系认证证书</div>
              </div>
              <div class="exhibit-img box">
                <img src="../assets/imgs/pictureFrame03.png" alt="" />
                <div class="title">职业健康安全管理体系认证证书</div>
              </div>
              <div class="exhibit-img box">
                <img src="../assets/imgs/pictureFrame04.png" alt="" />
                <div class="title">企业信息等级证书</div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="exhibit-img box">
                <img src="../assets/imgs/备案证书.jpg" alt="" />
                <div class="title">备案证书</div>
              </div>
              <div class="exhibit-img box">
                <img src="../assets/imgs/公示证书.jpg" alt="" />
                <div class="title">公示证书</div>
              </div>
              <div class="exhibit-img box">
                <img src="../assets/imgs/共建实习基地.jpg" alt="" />
                <div class="title">共建实习基地</div>
              </div>
              <div class="exhibit-img box">
                <img src="../assets/imgs/科学技术成果证书.jpg" alt="" />
                <div class="title">科学技术成果证书</div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="exhibit-img box">
                <img src="../assets/imgs/软件产品鉴定证书.jpg" alt="" />
                <div class="title">软件产品鉴定证书</div>
              </div>
              <div class="exhibit-img box">
                <img src="../assets/imgs/团体会员.jpg" alt="" />
                <div class="title">团体会员</div>
              </div>
              <div class="exhibit-img box">
                <img src="../assets/imgs/证书.jpg" alt="" />
                <div class="title">证书</div>
              </div>
              <div class="exhibit-img box">
                <img src="../assets/imgs/资质证书.jpg" alt="" />
                <div class="title">资质证书</div>
              </div>
            </el-carousel-item>
          </el-carousel>
          <div class="next">
            <em class="el-icon-arrow-right" @click="arrowClick('next')"></em>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show1: false,
    };
  },
  methods: {
    arrowClick(val) {
      if (val === "next") {
        this.$refs.cardShow.next();
      } else {
        this.$refs.cardShow.prev();
      }
    },
    enter1() {
      this.show1 = true;
    },
    out1() {
      this.show1 = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.introduction {
  margin-top: 76px;
  .title {
    height: 103px;
    line-height: 103px;
    span {
      display: block;
    }
    span:nth-child(1) {
      height: 30px;
      line-height: 30px;
      font-size: 26px;
      margin-bottom: 10px;
      font-weight: lighter;
      color: #959595;
    }
    span:nth-child(2) {
      height: 40px;
      line-height: 40px;
      font-size: 34px;
      color: #333333;
      margin-bottom: 20px;
    }
    span:nth-child(3) {
      width: 50px;
      height: 3px;
      margin: 0 auto;
      background-color: #333333;
      border-radius: 2px;
    }
  }
  .text-box {
    height: 445px;
    margin-top: 80px;
    position: relative;
    display: flex;
    justify-content: left;
    .text-box-left {
      width: 703px;
      height: 394px;
      position: absolute;
      left: 55px;
      top: 25px;
      z-index: 100;
      padding: 30px 50px;
      overflow: auto;
      box-sizing: border-box;
      h4 {
        height: 40px;
        line-height: 40px;
        text-align: left;
        font-size: 24px;
        font-weight: normal;
        color: #ffffff;
        margin-bottom: 16px;
      }
      span {
        display: block;
        width: 78px;
        height: 4px;
        background-color: #ffffff;
        border-radius: 2px;
      }
      .p-text {
        height: 222px;
        margin-top: 20px;
        line-height: 30px;
        color: #ffffff;
        font-size: 18px;
        letter-spacing: 1px;
        text-align: left;
        overflow: auto;
        padding-right: 20px;
        strong {
          font-size: 18px;
        }
        p {
          font-size: 18px;
        }
        .p1 {
          margin: 15px 0;
        }
      }
      background: rgba($color: #0280d4, $alpha: 0.5);
    }
    /*滚动条样式*/
    .p-text::-webkit-scrollbar {
      width: 4px;
      /*height: 4px;*/
    }
    .p-text::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 5px #ffffff;
      background: transparent;
    }
    // .p-text::-webkit-scrollbar-track {
    //   -webkit-box-shadow: inset 0 0 5px #ffffff;
    //   border-radius: 0;
    //   background: transparent;
    // }
    .text-box-right {
      width: 675px;
      height: 445px;
      position: absolute;
      right: 55px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.culture {
  height: 1600px;
  margin-top: 200px;
  background: url("../assets/imgs/culturalBackground2.png") no-repeat;
  background-size: 100%;
  position: relative;
  .culture-title {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: -75px;
    left: 50%;
    margin-left: -100px;
    background-color: #ff6000;
    .culture-title-text {
      width: 200px;
      height: 80px;
      color: #ffffff;
      position: absolute;
      top: 63px;
      left: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      i {
        width: 200px;
        display: block;
        font-style: normal;
        font-size: 15px;
        line-height: 15px;
        color: #ffffff;
        text-align: center;
      }
      span {
        display: block;
        height: 30px;
        line-height: 30px;
        font-size: 27px;
        color: #ffffff;
        margin-top: 10px;
        margin-bottom: 8px;
        font-weight: normal;
        text-align: center;
      }
      em {
        width: 38px;
        height: 3px;
        border-radius: 1px;
        background: #ffffff;
      }
    }
  }

  .vision {
    width: 630px;
    height: 152px;
    position: relative;
    left: 50px;
    top: 360px;
    span {
      display: block;
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: right;
      color: #7b5e3d;
      font-size: 28px;
    }
    p {
      line-height: 30px;
      color: #96846f;
      font-size: 18px;
      text-align: right;
      margin-bottom: 8px;
    }
    .imgs {
      width: 116px;
      height: 80px;
      float: right;
      overflow: hidden;
      position: relative;
    }
    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
      transition: all 0.9s;
    }
    // img:hover {
    //   transform: scale(1.2);
    // }
    .enlarge {
      position: absolute;
      right: -100px;
      top: 170px;
      width: 340px;
      height: 220px;
      opacity: 0;
      transition: all 0.9s;
      z-index: 1000;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .showstyle{
      opacity: 1;
    }
  }
  .core {
    width: 630px;
    height: 152px;
    position: relative;
    right: -725px;
    top: 460px;
    span {
      display: block;
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: left;
      color: #7b5e3d;
      font-size: 28px;
    }
    p {
      line-height: 30px;
      color: #96846f;
      font-size: 18px;
      text-align: left;
      margin-bottom: 8px;
    }
    .imgs {
      width: 116px;
      height: 80px;
      float: left;
      overflow: hidden;
      position: relative;
    }
    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
      transition: all 0.9s;
    }
    // img:hover {
    //   transform: scale(1.2);
    // }
    .enlarge {
      position: absolute;
      right: -100px;
      top: 170px;
      width: 340px;
      height: 220px;
      opacity: 0;
      transition: all 0.9s;
      z-index: 1000;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .showstyle{
      opacity: 1;
    }
  }
  .target {
    width: 630px;
    height: 152px;
    position: relative;
    left: 50px;
    top: 560px;
    span {
      display: block;
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: right;
      color: #7b5e3d;
      font-size: 28px;
    }
    p {
      line-height: 30px;
      color: #96846f;
      font-size: 18px;
      text-align: right;
      margin-bottom: 8px;
    }
    .imgs {
      width: 116px;
      height: 80px;
      float: right;
      overflow: hidden;
    }
    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
      transition: all 0.9s;
    }
    // img:hover {
    //   transform: scale(1.2);
    // }
  }
  .arts {
    width: 630px;
    height: 152px;
    position: relative;
    right: -725px;
    top: 668px;
    span {
      display: block;
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: left;
      color: #7b5e3d;
      font-size: 28px;
    }
    p {
      line-height: 30px;
      color: #96846f;
      font-size: 18px;
      text-align: left;
      margin-bottom: 8px;
    }
    .imgs {
      width: 116px;
      height: 80px;
      float: left;
      overflow: hidden;
    }
    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
      transition: all 0.9s;
    }
    // img:hover {
    //   transform: scale(1.2);
    // }
  }
  .spirit {
    width: 630px;
    height: 152px;
    position: relative;
    left: 50px;
    top: 760px;
    span {
      display: block;
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: right;
      color: #7b5e3d;
      font-size: 28px;
    }
    p {
      line-height: 30px;
      color: #96846f;
      font-size: 18px;
      text-align: right;
      margin-bottom: 8px;
    }
    .imgs {
      width: 116px;
      height: 80px;
      float: right;
      overflow: hidden;
    }
    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
      transition: all 0.9s;
    }
    // img:hover {
    //   transform: scale(1.2);
    // }
  }
}
.product-center {
  width: 100%;
  height: 742px;
  .product-center-banner {
    height: 100%;
    width: 1200px;
    margin: 0 auto;
  }
  .product-info {
    height: 105px;
    line-height: 105px;
    margin: 0 auto;
    margin-bottom: 74px;
    padding-top: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    i {
      font-style: normal;
      height: 40px;
      line-height: 40px;
      font-size: 20px;
      color: #959595;
      font-weight: lighter;
    }
    span {
      display: inline-block;
      width: 100%;
      height: 40px;
      line-height: 40px;
      font-size: 34px;
      color: #333333;
      margin-bottom: 20px;
    }
    .line {
      width: 50px;
      border-bottom: 3px solid #333333;
    }
  }
  /deep/ .product-carousel {
    height: 300px;
    position: relative;
    .prev {
      width: 66px;
      height: 120px;
      z-index: 100;
      text-align: center;
      position: absolute;
      left: -126px;
      top: 50%;
      margin-top: -60px;
      em {
        font-size: 100px;
        color: #cecece;
        cursor: pointer;
      }
    }
    .next {
      width: 66px;
      height: 120px;
      z-index: 100;
      text-align: center;
      position: absolute;
      right: -126px;
      top: 50%;
      margin-top: -60px;
      em {
        font-size: 100px;
        color: #cecece;
        cursor: pointer;
      }
    }
    .el-carousel__indicators--horizontal {
      display: none;
    }
    .exhibit-img {
      width: 251px;
      height: 217px;
      line-height: 217px;
      margin: 0 20px;
      cursor: pointer;
      img {
        width: 250px;
        height: 183px;
      }
    }
    .title {
      height: 60px;
      line-height: 30px;
      text-align: center;
      font-size: 18px;
      color: #646464;
    }
    .el-carousel__item {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .box {
    position: relative;
    display: block;
    overflow: hidden;
    img {
      display: block;
    }
  }
}
</style>